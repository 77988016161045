import React, { Suspense, useEffect, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Loader from "./components/UI/Loader";
import AuthContext from "./store/auth-context";
// import UserProfile from './components/Profile/UserProfile';
// import AuthPage from './pages/AuthPage';
// import HomePage from './pages/HomePage';
// import NotFound from './pages/NotFound';

const UserProfile = React.lazy(() =>
  import("./components/Profile/UserProfile")
);
const AuthPage = React.lazy(() => import("./pages/AuthPage"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

const routes = [
  {
    id: "r1",
    path: "/",
    element: <HomePage />,
  },
  {
    id: "r2",
    path: "/auth",
    element: <AuthPage />,
  },
  {
    id: "r3",
    path: "/profile",
    element: <UserProfile />,
  },
  {
    id: "r4",
    path: "*",
    element: <NotFound />,
  },
];

function App() {
  const authCtx = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!authCtx.isLoggedIn) {
      navigate('/auth')
    }
    else {
      if ( location.pathname.includes('auth') ) {
        navigate('/')
      }
    }
  }, [authCtx.isLoggedIn, navigate, location.pathname]);


  return (
    <Layout>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
