import { Fragment, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutClickHandler = () => {
    authCtx.logout()
    navigate('/auth')
  }

  return (
    <header className={classes.header}>
      <NavLink to="/">
        <div className={classes.logo}>React Auth</div>
      </NavLink>
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <NavLink
                to="/auth"
                className={(navData) =>
                  navData.isActive ? classes.active : ""
                }
              >
                Login
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <Fragment>
              <li>
                <NavLink
                  to="/profile"
                  className={(navData) =>
                    navData.isActive ? classes.active : ""
                  }
                >
                  Profile
                </NavLink>
              </li>
              <li>
                <button onClick={logoutClickHandler}>Logout</button>
              </li>
            </Fragment>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
